import axios from "axios";
import React, { useEffect, useState } from "react";
import Breadcrumbs from "../layouts/Breadcrumbs";
// import { token } from "../token/token";



const AllDataMipp = (props) => {
  const [layanan, setLayanan] = useState([]);
  const [cari, setCari] = useState('');
//   console.log(cari);

  function getLayanan() {
    axios({
      method: "get",
      url: "https://mipp.demakkab.go.id/api/list-web"
      // url: "https://api-splp.layanan.go.id:443/t/demakkab.go.id/MIPP_DEMAK/1/list-web",
      // headers: token,
    })
      //  axios.get(url,{headers})
      .then(function (response) {
        // console.log(response.data.data);
        setLayanan(response.data.data);
      })
      .catch(function (error) {
        // console.log(error);
      });
  }
  useEffect(() => {
    getLayanan();
    const interval = setInterval(() => {
      getLayanan();
    }, 5000);
    return () => clearInterval(interval);
  }, []);
  return (
    <div>
      <Breadcrumbs />
      <section className="inner-page p-panduan">
        <div className="container card p-4 shadow">
          <div className="text-center">
            <h5>
              <b className="shadow rounded p-2 title-panduan">
                Layanan Mall Informasi Pelayanan Publik
              </b>
            </h5>
            <div className="row pt-4">
              <div className="pb-2">
                <input
                  type="text"
                  id="cari"
                  className="form-control" onChange={(e) => setCari(e.target.value)}
                  placeholder="Cari Layanan Wali Warga..."
                />
              </div>
              {layanan &&
                layanan.filter((layanan) => {
                    return cari.toLowerCase() === '' ? layanan : layanan.nama_web.toLowerCase().includes(cari)
                }).map((layanan, index) => (
                  <div className="col-md-2 pt-1" key={layanan.id}>
                    <div id="list-cari">
                      <div className="card">
                        <button
                          type="button"
                          className="rounded"
                          data-bs-toggle="modal"
                          data-bs-target={"#staticBackdrop" + layanan.id}
                        >
                          <a href={layanan.link} target="blank">
                            <img
                              src={
                                "https://mipp.demakkab.go.id/storage/" +
                                layanan.icon
                              }
                              alt=""
                              className="img-fluid p-4 img-layananmipp"
                              width="100%"
                            />
                          </a>
                          <p>
                            <b className="font-size-layananmipp">
                              {layanan.nama_web.substring(0, 17)}
                            </b>
                          </p>
                        </button>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </section>
      <div>
          
      </div>
    </div>
  );
};

export default AllDataMipp;
