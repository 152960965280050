import axios from "axios";
import React, { useEffect, useState } from "react";
// import { token } from "../token/token";

const DataInfografis = (props) => {
  const [infografis, setInfografis] = useState([]);
  const [infografis2, setInfografis2] = useState([]);
  const [errorMessage, setErrorMessage] = useState(null);

  function getInfografis() {
    axios({
      method: "get",
      url: "https://satudata.demakkab.go.id/api/infografis?page=1"
      // url: "https://api-splp.layanan.go.id/t/demakkab.go.id/SATUDATA_DEMAK/1/infografis?page=1",
      // headers: token,
    })
      .then(function (response) {
        // console.log(response.data.data);
        setInfografis(response.data.data);
      })
      .catch(function (error) {
        setErrorMessage(
          " 'Sorry, Get Data API is Having Problems, Please Reload This Page ( F5 )'"
        );
        console.log(error);
      });
  }
  function getInfografis2() {
    axios({
      method: "get",
      url: "https://satudata.demakkab.go.id/api/infografis?page=2"
      // url: "https://api-splp.layanan.go.id/t/demakkab.go.id/SATUDATA_DEMAK/1/infografis?page=2",
      // headers: token,
    })
      .then(function (response) {
        // console.log(response.data.data);
        setInfografis2(response.data.data);
      })
      .catch(function (error) {
        // console.log(error);
        setErrorMessage(
          " 'Sorry, Get Data API is Having Problems, Please Reload This Page ( F5 )'"
        );
      });
  }
  useEffect(() => {
    getInfografis();
    const interval = setInterval(() => {
      getInfografis();
    }, 5000);
    return () => clearInterval(interval);
  }, []);
  useEffect(() => {
    getInfografis2();
    const interval = setInterval(() => {
      getInfografis2();
    }, 5000);
    return () => clearInterval(interval);
  }, []);
  return (
    <div>
      <div className="row">
        <div
          id="carouselExampleControlsNoTouching3"
          className="carousel slide"
          data-bs-touch="false"
          data-bs-interval="false"
        >
          <div className="carousel-inner">
            <div className="carousel-item active">
              <div className="row">
              {errorMessage && <div className="text-center p-4 pt-4"> <p>{errorMessage}</p></div>}
                {infografis &&
                  infografis.map((info, index) => (
                    <div className="col-md-6 pt-1" key={info.id}>
                      <div className="card bordered hover-bg-black rounded p-1">
                        <div className="h-1-card">
                          <a
                            title={info.judul}
                            href={
                              "https://satudata.demakkab.go.id/storage/content/infografis/" +
                              info.img
                            }
                            target="_blank"
                          >
                            <img
                              src={
                                "https://satudata.demakkab.go.id/storage/content/infografis/" +
                                info.img
                              }
                              className="img-fluid rounded shadow img-infografis"
                              alt=""
                            />
                          </a>
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
            <div className="carousel-item">
              <div className="row">
              {errorMessage && <div className="text-center p-4 pt-4"> <p>{errorMessage}</p></div>}
                {infografis2 &&
                  infografis2.map((info, index) => (
                    <div className="col-md-6 pt-1" key={info.id}>
                      <div className="card bordered hover-bg-black rounded p-1">
                        <div className="h-1-card">
                          <a
                            title={info.judul}
                            href={
                              "https://satudata.demakkab.go.id/storage/content/infografis/" +
                              info.img
                            }
                            target="_blank"
                          >
                            <img
                              src={
                                "https://satudata.demakkab.go.id/storage/content/infografis/" +
                                info.img
                              }
                              className="img-fluid rounded shadow img-infografis"
                              alt=""
                            />
                          </a>
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </div>
          <button
            className="carousel-control-prev btn-w-2"
            type="button"
            data-bs-target="#carouselExampleControlsNoTouching3"
            data-bs-slide="prev"
          >
            <span
              className="carousel-control-prev-icon bg-btn-slide"
              aria-hidden="true"
            ></span>
            <span className="visually-hidden">Previous</span>
          </button>
          <button
            className="carousel-control-next btn-w-2"
            type="button"
            data-bs-target="#carouselExampleControlsNoTouching3"
            data-bs-slide="next"
          >
            <span
              className="carousel-control-next-icon bg-btn-slide"
              aria-hidden="true"
            ></span>
            <span className="visually-hidden">Next</span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default DataInfografis;
