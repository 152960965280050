import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const DataDsc = (props) => {
  const [layanan, setLayanan] = useState([]);
  const [layanan2, setLayanan2] = useState([]);
  const [errorMessage, setErrorMessage] = useState(null);
  function getLayanan() {
    axios({
      method: "get",
      url: "https://api.dsc.demakkab.go.id/category/offset/0/limit/12",
    })
      //  axios.get(url,{headers})
      .then(function (response) {
        // console.log(response.data.data);
        setLayanan(response.data.data);
      })
      .catch(function (error) {
        // console.log(error);
        setErrorMessage(
          "'Sorry, Get Data API is Having Problems, Please Reload This Page ( F5 )'"
        );
      });
  }
  function getLayanan2() {
    axios({
      method: "get",
      url: "https://api.dsc.demakkab.go.id/category/offset/12/limit/24",
    })
      .then(function (response) {
        // console.log(response.data.data);
        setLayanan2(response.data.data);
      })
      .catch(function (error) {
        // console.log(error);
        setErrorMessage(
          "'Sorry, Get Data API is Having Problems, Please Reload This Page ( F5 )'"
        );
      });
  }
  useEffect(() => {
    getLayanan();
  }, []);

  useEffect(() => {
    getLayanan2();
  }, []);
  return (
    <div>
      <section id="about" className="about p-1">
        <div className="container">
          <div className="section-title">
            <p className="p-dsc">Smart Center</p>
            <h1 className="h-dsc">Kategori aplikasi DSC Wali Warga Demak</h1>
          </div>
          <div
            id="carouselExampleControlsNoTouching2"
            className="carousel slide"
            data-bs-touch="false"
            data-bs-interval="false"
          >
            <div className="carousel-inner">
              <div className="carousel-item active">
                <div className="row">
                  
                    {errorMessage && <div className="text-center p-4 pt-4"> <p>{errorMessage}</p></div>}
                  
                  {layanan &&
                    layanan.map((layanan, index) => (
                      <div className="col-md-2 pt-1" key={layanan.cid}>
                        <div className="card bordered hover-bg-black rounded p-1">
                          <div className="card">
                            <button
                              type="button"
                              className="rounded"
                              data-bs-toggle="modal"
                              data-bs-target={"#staticBackdrop" + layanan.cid}
                            >
                              <Link to={"/layanan_dsc/" + layanan.cid}>
                                <img
                                  src={
                                    "https://dsc.demakkab.go.id/images/" +
                                    layanan.category_image
                                  }
                                  alt=""
                                  className="p-4"
                                  height="120px;"
                                  width="100%"
                                />
                              </Link>
                              <p>
                                <b>{layanan.category_name.substring(0, 13)}</b>
                              </p>
                            </button>
                          </div>
                        </div>
                      </div>
                    ))}
                </div>
              </div>
              <div className="carousel-item">
                <div className="row">
                {errorMessage && <div className="text-center p-4 pt-4"> <p>{errorMessage}</p></div>}
                  {layanan2 &&
                    layanan2.map((layanan2, index) => (
                      <div className="col-md-2 pt-1" key={layanan2.cid}>
                        <div className="card bordered hover-bg-black rounded p-1">
                          <div className="card">
                            <button
                              type="button"
                              className="rounded"
                              data-bs-toggle="modal"
                              data-bs-target={"#staticBackdrop" + layanan2.cid}
                            >
                              <Link to={"/layanan_dsc/" + layanan2.cid}>
                                <img
                                  src={
                                    "https://dsc.demakkab.go.id/images/" +
                                    layanan2.category_image
                                  }
                                  alt=""
                                  className="p-4"
                                  height="120px;"
                                  width="100%"
                                />
                              </Link>
                              <p>
                                <b>{layanan2.category_name.substring(0, 13)}</b>
                              </p>
                            </button>
                          </div>
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            </div>
            <button
              className="carousel-control-prev btn-w-2"
              type="button"
              data-bs-target="#carouselExampleControlsNoTouching2"
              data-bs-slide="prev"
            >
              <span
                className="carousel-control-prev-icon bg-btn-slide"
                aria-hidden="true"
              ></span>
              <span className="visually-hidden">Previous</span>
            </button>
            <button
              className="carousel-control-next btn-w-2"
              type="button"
              data-bs-target="#carouselExampleControlsNoTouching2"
              data-bs-slide="next"
            >
              <span
                className="carousel-control-next-icon bg-btn-slide"
                aria-hidden="true"
              ></span>
              <span className="visually-hidden">Next</span>
            </button>
          </div>
          <div className="row content pb-4"></div>
        </div>
      </section>
    </div>
  );
};

export default DataDsc;
