import React from 'react'
import { Link } from 'react-router-dom'

const AdminDashboard = () => {
  return (
    <div>
        <h1>Admin Dashboard</h1>
        <div className="container">
                <Link exact="true" to={"/logout"} className="btn btn-primary btn-sm">
                  <b>Logout</b>
                </Link>
              </div>
    </div>
  )
}

export default AdminDashboard