import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Breadcrumbs from '../layouts/Breadcrumbs';


const DetailBerita = () => {
  const [detail, setDetail] = useState(null);
  const { id } = useParams();
  
  function getDetail() {
    axios({
      method: "get",
      url: `https://demakkab.go.id/api/post/${id}` ,
    })
      //  axios.get(url,{headers})
      .then(function (response) {
        // console.log(response.data.data[0]);
        setDetail(response.data.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  }
  useEffect(() => {
    getDetail();
  }, [id]);
  return (
    <div>
        <Breadcrumbs />
        <section id="about" className="about">
            <div className="container">
                <div className="section-title pt-4" data-aos="zoom-out">
                    <h2 className="h-title-berita">Detail Berita</h2>
                </div>
                <div className="row">
                    <div className="col-md-10">
                    {detail &&
                    detail.map((detail, index) => (
                        <div className="card p-1" width="100%" key={detail.id}>
                            <img
                                src={"https://demakkab.go.id/" + detail.gambar}
                                className="card-img img-berita img-detail-berita"
                                alt="..."
                            />
                            <div className="card-body">
                            <h3><p className="card-text"><b>{detail.judul}</b></p></h3>
                                <hr/>
                                <p>{detail.content.replaceAll(/<[^>]+>/g,'').replaceAll('&ndash;','').replaceAll('&ldquo;','').replaceAll('&rdquo;','').replaceAll('.&nbsp;','').replaceAll('&#39;','').replaceAll('&quot;','').replaceAll('&rsquo;',"'")}</p>
                                <p ><small className="text-muted">{ detail.created_at}</small></p>
                        </div>
                        </div> 
                    ))}
                       
                    </div>
                    <div className="col-md-2">

                    </div>
                </div>
            </div>
        </section>
    </div>
  )
}

export default DetailBerita