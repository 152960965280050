import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Breadcrumbs from "../layouts/Breadcrumbs";

const DetailDsc = (props) => {
  const [detaildsc, setDetaildsc] = useState([]);
  const [detaildata, setDetaildata] = useState([]);
  const { id } = useParams();
  const [cari, setCari] = useState('');

  function getDetailDsc() {
    axios({
      method: "get",
      url: `https://api.dsc.demakkab.go.id/places/cat/${id}`,
    })
      .then(function (response) {
        // console.log(response.data.data);
        setDetaildata(response.data.data);
        setDetaildsc(response.data.data[0]);
      })
      .catch(function (error) {
        // console.log(error);
      });
  }
  useEffect(() => {
    getDetailDsc();
  }, []);
  return (
    <div>
      <Breadcrumbs />
      <section className="inner-page p-panduan">
        <div className="container card p-4 shadow">
          <div className="text-center">
            <h5>
              <b className="shadow rounded p-2 title-panduan">
                Layanan Demak Smart City - ( <b>{detaildsc.category_name}</b> ) 
              </b>
            </h5>
            <div className="row pt-4">
              <div className="pb-2">
                <input
                  type="text"
                  id="cari"
                  className="form-control"
                  onChange={(e) => setCari(e.target.value)}
                  placeholder={"Cari " + detaildsc.category_name + " Disini.."}
                />
              </div>
              {detaildata &&
                detaildata.filter((detaildata) => {
                    return cari.toLowerCase() === '' ? detaildata : detaildata.place_name.toLowerCase().includes(cari)
                }).map((detaildata, index) => (
                  <div className="col-md-2 pt-1" key={detaildata.p_id}>
                    <div id="list-cari">
                      <div className="card">
                                <button type="button" className="rounded" data-bs-toggle="modal"
                                    data-bs-target={"#staticBackdrop" + detaildata.p_id }>
                                    <img src={"https://dsc.demakkab.go.id/images/"+ detaildata.place_image} alt=""
                                     width="100%" height= "150px;"/>
                                    <p className="detail-name"><b
                                            className="font-size-dsc">{ detaildata.place_name.substring(0,28) }</b></p>
                                </button>
                      </div>
                    </div>
                    {/* modal data */}
                    <div className="modal fade modal-md" id={"staticBackdrop" +  detaildata.p_id } data-bs-backdrop="static"
                            data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel"
                            aria-hidden="true">
                        <div className="modal-dialog modal-dialog-scrollable">
                            <div className="modal-content">
                                    <div className="modal-header">
                                        <h6 className="modal-title" id="staticBackdropLabel"><b>{ detaildata.place_name }</b>
                                        </h6>
                                        <button type="button" className="btn-close" data-bs-dismiss="modal"
                                            aria-label="Close"></button>
                                    </div>
                                    <div className="modal-body">
                                        <div className="row">
                                            <div className="col-md-12 card rounded p-1 shadow bg-modal-dsc">
                                                <img src={"https://dsc.demakkab.go.id/images/" + detaildata.place_image}
                                                    alt="" width="100%" height="200px;"/>
                                                 <iframe
                                                    src={`https://maps.google.com/maps?q=${detaildata.place_map_latitude},${detaildata.place_map_longitude}&&hl=en&z=14&output=embed`}
                                                    width="100%" frameBorder="0" border="0" 
                                                    allowFullScreen></iframe>
                                                
                                                <div className="p-2">
                                                    <p className="mb-0">Lokasi : <b
                                                            className="font-size-dsc">{detaildata.place_address }</b></p>
                                                    <p className="mb-0">Website : <a href={detaildata.place_website}
                                                            target="_blank"><b
                                                                className="font-size-dsc2">{detaildata.place_website}</b></a>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                            </div>
                        </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default DetailDsc;
