import React, { Fragment } from "react";
import { Route, Routes, } from "react-router-dom";
import AdminDashboard from "../admin/dashboard/AdminDashboard";
import Login from "../auth/Login";
import Logout from "../auth/Logout";
import DetailBerita from "../berita/DetailBerita";
import Dashboard from "../Dashboard";
import Error404 from "../error/Error404";
import Faq from "../Faq";
import DetailDsc from "../layanandsc/DetailDsc";
import AllDataMipp from "../layananmipp/AllDataMipp";
import Panduan from "../Panduan";

const RouteComponent = (props) => {
  return (
    <Routes>
      {props.token ? (
        <>
        <Route exact="true" path="/admin/dashboard" element={<AdminDashboard role={props.role}/>}  />
        <Route exact="true" path="/" element={<Dashboard/>} />
          <Route exact="true" path="/beranda" element={<Dashboard />} />
          <Route exact="true" path="/panduan" element={<Panduan />} />
          <Route exact="true" path="/layanan_mipp" element={<AllDataMipp />} />
          <Route exact="true" path="/faq" element={<Faq />} />
          <Route
            exact="true"
            path="/detail_berita/:id"
            element={<DetailBerita />}
          />
          <Route exact="true" path="/layanan_dsc/:id" element={<DetailDsc />} />

          <Route
             path="/logout"
             element={<Logout token={props.token} setToken={props.setToken} setRole={props.setRole} />}
           />
        </>
      ) : (
        <>
          <Route exact="true" path="/auth/login" element={<Login setToken={props.setToken} setRole={props.setRole} />} />
          <Route exact="true" path="/" element={<Dashboard/>} />
          <Route exact="true" path="/beranda" element={<Dashboard />} />
          <Route exact="true" path="/panduan" element={<Panduan />} />
          <Route exact="true" path="/layanan_mipp" element={<AllDataMipp />} />
          <Route exact="true" path="/faq" element={<Faq />} />
          <Route
            exact="true"
            path="/detail_berita/:id"
            element={<DetailBerita />}
          />
          <Route exact="true" path="/layanan_dsc/:id" element={<DetailDsc />} />
        </>
      )}
      ;
      <Route exact="true" path="*" element={<Error404 />} />
     
    </Routes>
  );
};

export default RouteComponent;
