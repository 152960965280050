import React from "react";
import Breadcrumbs from "./layouts/Breadcrumbs";

const Panduan = (props) => {
  return (
    <div>
      <title>Wali Warga Demak - Panduan</title>
      <Breadcrumbs />
      <section className="inner-page p-panduan">
        <div className="container card p-4 shadow">
          <div className="text-center">
            <h5>
              <b className="shadow rounded p-2 title-panduan">
                Panduan Instalasi Wali Warga
              </b>
            </h5>
          </div>
          <div className="accordion accordion-flush p-2 pt-2" id="accordionFlushExample">
            <div className="accordion-item">
              <h2 className="accordion-header" id="flush-headingTwo">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#flush-collapseTwo"
                  aria-expanded="false"
                  aria-controls="flush-collapseTwo"
                >
                  Bagaimana Instalasi Aplikasi Wali Warga di SmartPhone?
                </button>
              </h2>
              <div
                id="flush-collapseTwo"
                className="accordion-collapse collapse"
                aria-labelledby="flush-headingTwo"
                data-bs-parent="#accordionFlushExample"
              >
                <div className="accordion-body">
                  <p>
                    {" "}
                    Untuk SmartPhone Dengan OS Android, Silahkan Masuk Ke
                    Playstore Kemudian Search Pencarian Dengan Kata Kunci Wali
                    Warga atau Search Dengan Kata Kunci Dinkominfo Demak,
                    Kemudian Pilih Aplikasi Wali Warga, Selanjutnya Pilih Dan
                    Install
                  </p>
                  <p>
                    Untuk SmartPhone Dengan OS IOS Sementara Aplikasi Wali Warga
                    Belum Terdesia.
                  </p>
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header" id="flush-headingThree">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#flush-collapseThree"
                  aria-expanded="false"
                  aria-controls="flush-collapseThree"
                >
                  Bagaimana Cara Menggunakan Aplikasi Wali Warga ?
                </button>
              </h2>
              <div
                id="flush-collapseThree"
                className="accordion-collapse collapse"
                aria-labelledby="flush-headingThree"
                data-bs-parent="#accordionFlushExample"
              >
                <div className="accordion-body">
                  <p>
                    Untuk Panduan Cara Penggunaan Aplikasi Wali Warga, Silahkan
                    Download Panduan <a href="3">Disini !</a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Panduan;
