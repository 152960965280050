import React from "react";

const Aduan = (props) => {
  return (
    <div>
    <div className="mb-4">
      <div className="section-title">
        <p className="p-dsc">Pengaduan</p>
        <h1 className="h-dsc">Pantau aduan masyarakat Kabupaten Demak</h1>
      </div>
      <div className="text-center">
        <a
          href="https://www.lapor.go.id/instansi/pemerintah-kabupaten-demak"
          target="blank"
          className="btn btn-outline-danger btn-sm"
        >
          <h6 className="h-dsc">
            <i className="bi bi-send"></i>
            <b className="p-2">Buat Pengaduan Layanan </b>
          </h6>
        </a>
      </div>
      </div>
    </div>
  );
};

export default Aduan;
