import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Aduan from "../aduan/Aduan";
import VideoInfografis from "../infografis/VideoInfografis";
// import { token } from "../token/token";

const DataBerita = (props) => {
  const [berita, setBerita] = useState([]);
  const [berita2, setBerita2] = useState([]);
  const [errorMessage, setErrorMessage] = useState(null);

  function getBerita() {
    axios({
      method: "get",
      url: "https://demakkab.go.id/api/getberita"
      // url: "https://api-splp.layanan.go.id:443/t/demakkab.go.id/Portal_Demakkab/1/getberita?page=1",
      // headers: token,
    })
      //  axios.get(url,{headers})
      .then(function (response) {
        // console.log(response.data.data);
        setBerita(response.data.data);
      })
      .catch(function (error) {
        // console.log(error);
        setErrorMessage(
          "'Sorry, Get Data API is Having Problems, Please Reload This Page ( F5 )'"
        );
      });
  }
  useEffect(() => {
    getBerita();
    const interval = setInterval(() => {
      getBerita();
    }, 5000);
    return () => clearInterval(interval);
  }, []);
  //getberita2
  function getBerita2() {
    axios({
      method: "get",
      url: "https://demakkab.go.id/api/getberita?page=2"
      // url: "https://api-splp.layanan.go.id:443/t/demakkab.go.id/Portal_Demakkab/1/getberita?page=2",
      // headers: token,
    })
      //  axios.get(url,{headers})
      .then(function (response) {
        // console.log(response.data.data);
        setBerita2(response.data.data);
      })
      .catch(function (error) {
        // console.log(error);
        setErrorMessage(
          "'Sorry, Get Data API is Having Problems, Please Reload This Page ( F5 )'"
        );
      });
  }
  useEffect(() => {
    getBerita2();
    const interval = setInterval(() => {
      getBerita2();
    }, 5000);
    return () => clearInterval(interval);
  }, []);
  return (
    <div>
      <section id="about" className="about">
        <div className="container">
          <div className="section-title">
            <p className="p-dsc">Berita Utama</p>
            <h1 className="h-dsc">Jelajahi Berita Wali Warga Demak</h1>
          </div>
          {/* slider */}
          <div
            id="carouselExampleControls"
            className="carousel slide"
            data-bs-ride="carousel"
          >
            <div className="carousel-inner">
              <div className="carousel-item mb-4 active">
                <div className="row">
                {errorMessage && <div className="text-center p-4 pt-4"> <p>{errorMessage}</p></div>}
                  {berita &&
                    berita.map((berita, index) => (
                      <div className="col-sm-3 pt-2" key={berita.id}>
                        <div className="card bg-dark text-white p-1">
                          <img
                            src={"https://demakkab.go.id/" + berita.gambar}
                            className="card-img img-berita"
                            alt="..."
                          />
                          <div className="card-img-overlay">
                            <Link
                              to={`/detail_berita/${berita.id}`}
                              className="btn btn-warning btn-sm"
                            >
                              <small>{berita.judul.substring(0, 20)}</small>
                            </Link>
                          </div>
                        </div>
                      </div>
                    ))}
                </div>
              </div>
              <div className="carousel-item">
                <div className="row">
                {errorMessage && <div className="text-center p-4 pt-4"> <p>{errorMessage}</p></div>} 
                  {berita2 &&
                    berita2.map((berita2, index) => (
                      <div className="col-sm-3 pt-2" key={berita2.id}>
                        <div className="card bg-dark text-white p-1">
                          <img
                            src={"https://demakkab.go.id/" + berita2.gambar}
                            className="card-img img-berita"
                            alt="..."
                          />
                          <div className="card-img-overlay">
                            <Link
                              to={`/detail_berita/${berita2.id}`}
                              className="btn btn-warning btn-sm"
                            >
                              <small>{berita2.judul.substring(0, 20)}</small>
                            </Link>
                          </div>
                        </div>
                      </div>
                    ))}
                  ;
                </div>
              </div>
            </div>
            <button
              className="carousel-control-prev btn-w-2"
              type="button"
              data-bs-target="#carouselExampleControls"
              data-bs-slide="prev"
            >
              <span
                className="carousel-control-prev-icon"
                aria-hidden="true"
              ></span>
              <span className="visually-hidden">Previous</span>
            </button>
            <button
              className="carousel-control-next btn-w-2"
              type="button"
              data-bs-target="#carouselExampleControls"
              data-bs-slide="next"
            >
              <span
                className="carousel-control-next-icon"
                aria-hidden="true"
              ></span>
              <span className="visually-hidden">Next</span>
            </button>
          </div>
          {/* end */}
          <div className="section-title mt-2">
            <a href="https://demakkab.go.id/berita" target="blank">
              <h2 className="text-selengkapnya">
                <b>
                  <i className="bi bi-arrow-right-circle"></i>
                  lihat semua berita{" "}
                </b>
              </h2>
            </a>
          </div>
          {/* data aduan */}
          <Aduan />
          <VideoInfografis />
        </div>
      </section>
    </div>
  );
};

export default DataBerita;
