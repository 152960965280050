import React, { useState } from "react";
import { Nav } from "react-bootstrap";
import { Link, NavLink, useLocation } from "react-router-dom";
const Navbar = (props) => {
  const location = useLocation();
  const [activeLink, setActiveLink] = useState('');

  const handleLinkClick = (link) => {
    setActiveLink(link);
  };
  // Daftar URL yang akan menonaktifkan navbar
  const disabledUrls = ['/auth/login', '/admin/dashboard'];

  // Periksa apakah URL saat ini ada dalam daftar yang dinonaktifkan
  const shouldShowNavbar = !disabledUrls.includes(location.pathname);

  if (!shouldShowNavbar) {
    return null;
  }
  return(
    <div>
      <header
        id="header"
        className="fixed-top d-flex align-items-center  header-transparent "
      >
        <div className="container d-flex align-items-center justify-content-between">
          <Link exact="true" to={"/"}>
          <h6>
              <img src="/Selecao/assets/img/logo.png" width="30%;" />
              <b className="color-h-nav"> Wali Warga </b>
            </h6>          
          </Link>

          <nav id="navbar" className="navbar">
            <ul>
              <Nav.Link
                as={NavLink}
                exact="true"
                to="/beranda"
                className={activeLink === 'beranda' ? 'active' : ''}
                onClick={() => handleLinkClick('beranda')}
              >
                Beranda
              </Nav.Link>
              
              {/* <Nav.Link
                as={NavLink}
                exact="true"
                to="/beranda"
                isActive={() => location.pathname === "/beranda"}
              >
                Beranda
              </Nav.Link> */}
              <Nav.Link
                as={NavLink}
                exact = "true"
                to="/panduan"
                className={activeLink === 'panduan' ? 'active' : ''}
                onClick={() => handleLinkClick('panduan')}
              >
                Panduan
              </Nav.Link>
              <li>
                <a
                  className="nav-link scrollto"
                  href="/users_manual_book_waliwarga.pdf"
                >
                  Manual Book
                </a>
              </li>
              <Nav.Link
                as={NavLink}
                exact = "true"
                to="/faq"
                className={activeLink === 'faq' ? 'active' : ''}
                onClick={() => handleLinkClick('faq')}
              >
                FAQ
              </Nav.Link>
            </ul>

              <i className="bi bi-list mobile-nav-toggle">
            <Link
              to=""
              data-bs-toggle="modal"
              data-bs-target="#staticBackdropmodal"
            >
            </Link>
              </i>
            <div
              className="modal fade modal-md"
              id="staticBackdropmodal"
              data-bs-backdrop="static"
              data-bs-keyboard="false"
              tabIndex="-1"
              aria-labelledby="staticBackdropLabel"
              aria-hidden="true"
            >
              <div className="modal-dialog modal-dialog-scrollable">
                <div className="modal-content">
                  <div className="modal-header">
                    <h6 className="modal-title" id="staticBackdropLabel">
                      <b>Wali Warga Demak</b>
                    </h6>
                    <button
                      type="button"
                      className="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    ></button>
                  </div>
                  <div className="modal-body">
                    <div className="list-group">
                      <Link to="/beranda" className="bg-link-nav">
                        <b>Beranda</b>
                      </Link>
                      <Link to="/panduan" className="bg-link-nav mt-1">
                        <b>Panduan</b>
                      </Link>

                      <a
                        className="bg-link-nav mt-1"
                        href="/users_manual_book_waliwarga.pdf"
                      >
                        <b className="text-font">Manual Book</b>
                      </a>
                      <Link to="/faq" className="bg-link-nav mt-1">
                        <b className="text-font">Faq</b>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </nav>
        </div>
      </header>
    </div>
  );
};

export default Navbar;
