import React from "react";
import { useLocation } from "react-router-dom";
import Visitor from "../visitor/Visitor";

const Footer = () => {
  const location = useLocation();

  // Daftar URL yang akan menonaktifkan navbar
  const disabledUrls = ['/auth/login', '/admin/dashboard'];

  // Periksa apakah URL saat ini ada dalam daftar yang dinonaktifkan
  const shouldShowNavbar = !disabledUrls.includes(location.pathname);

  if (!shouldShowNavbar) {
    return null;
  }
  return (
    <div>
      <footer id="footer">
        <div className="container">
          <div className="row">
            <div className="col-md-4">
              <p className="text-footer">
                Alamat : <br /> Jl. Sultan Hadiwijaya No. 4 Demak 59515 <br />{" "}
                Telephone : 0291 – 685790
              </p>
              <div className="social-links">
                <a href="#" target="blank" className="facebook">
                  <i className="bx bxl-facebook"></i>
                </a>
                <a href="#" target="blank" className="instagram">
                  <i className="bx bxl-instagram"></i>
                </a>
                <a href="#" target="blank" className="twitter">
                  <i className="bx bxl-twitter"></i>
                </a>
              </div>
            </div>
            <div className="col-md-4">
              <p className="text-footer">
                Apa sih Wali Warga itu ? Wali Warga adalah Sebuah Portal Virtual
                Pemerintah Kabupaten Demak dalam rangka memberikan layanan
                langsung kepada semua masyarakat di Kabupaten Demak
              </p>
              <div className="copyright">
                2023 &copy; Copyright{" "}
                <strong>
                  <span>Wali Warga</span>
                </strong>
                . All Rights Reserved
              </div>
            </div>
            <div className="col-md-2">
                <div className="text-f-link">
                <h5>Link Terekait</h5>
                    <a href="https://mipp.demakkab.go.id/" target="blank">MIPP DEMAK</a> <br/>
                    <a href="https://jogosantri.demakkab.go.id/" target="blank">JOGO SANTRI</a> <br/>
                    <a href="https://satudata.demakkab.go.id/" target="blank">SATUDATA DEMAK</a> <br/>
                    <a href="https://demakkab.go.id/" target="blank">PORTAL DEMAKKAB</a><br/>
                    <a href="https://dinkominfo.demakkab.go.id/" target="blank">DINKOMINFO DEMAK</a> <br/>
                </div>
            </div>
            <div className="col-md-2">
                <a href="https://play.google.com/store/apps/details?id=com.rb.waliwarga.demak"
                className="btn btn-outline-warning text-center"><i
                    className="bi bi-google-play"></i> Google Play</a>
                {/* <Visitor /> */}
            </div>
            
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
