import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
// import { token } from "../token/token";

const DataMipp = (props) => {
  const [layanan, setLayanan] = useState([]);
  const [layanan2, setLayanan2] = useState([]);
  const [layanan3, setLayanan3] = useState([]);
  const [errorMessage, setErrorMessage] = useState(null);

  function getLayanan() {
    axios({
      method: "get",
      // url: "https://api-splp.layanan.go.id:443/t/demakkab.go.id/MIPP_DEMAK/1/list-web-page?page=1",
      // headers: token,
      url : "https://mipp.demakkab.go.id/api/list-web-page"
    })
      //  axios.get(url,{headers})
      .then(function (response) {
        // console.log(response.data.data.data);
        setLayanan(response.data.data.data);
      })
      .catch(function (error) {
        // console.log(error);
        setErrorMessage(
          "'Sorry, Get Data API is Having Problems, Please Reload This Page ( F5 )'"
        );
      });
  }
  useEffect(() => {
    getLayanan();
    const interval = setInterval(() => {
      getLayanan();
    }, 5000);
    return () => clearInterval(interval);
  }, []);
  // layanan 2
  function getLayanan2() {
    axios({
      method: "get",
      url:"https://mipp.demakkab.go.id/api/list-web-page?page=2"
      // url: "https://api-splp.layanan.go.id:443/t/demakkab.go.id/MIPP_DEMAK/1/list-web-page?page=2",
      // headers: token,
    })
      //  axios.get(url,{headers})
      .then(function (response) {
        // console.log(response.data.data.data);
        setLayanan2(response.data.data.data);
      })
      .catch(function (error) {
        // console.log(error);
        setErrorMessage(
          "'Sorry, Get Data API is Having Problems, Please Reload This Page ( F5 )'"
        );
      });
  }
  useEffect(() => {
    getLayanan2();
  
  }, []);
  // layanan 3
  function getLayanan3() {
    axios({
      method: "get",
      url: "https://mipp.demakkab.go.id/api/list-web-page?page=2"
      // url: "https://api-splp.layanan.go.id:443/t/demakkab.go.id/MIPP_DEMAK/1/list-web-page?page=3",
      // headers: token,
    })
      //  axios.get(url,{headers})
      .then(function (response) {
        // console.log(response.data.data.data);
        setLayanan3(response.data.data.data);
      })
      .catch(function (error) {
        // console.log(error);
        setErrorMessage(
          "'Sorry, Get Data API is Having Problems, Please Reload This Page ( F5 )'"
        );
      });
  }
  useEffect(() => {
    getLayanan3();
   
  }, []);
  return (
    <div>
      <section id="about" className="about padding-section">
        <div className="container">
          <div className="section-title pt-4">
            <p className="judul-text-section">Layanan Wali Warga</p>
          </div>

          <div className="row content">
            <div
              id="carouselExampleControlsNoTouching"
              className="carousel slide"
              data-bs-touch="false"
              data-bs-interval="false"
            >
              <div className="carousel-inner">
                <div className="carousel-item active">
                  <div className="row">
                  {errorMessage && <div className="text-center p-4 pt-4"> <p>{errorMessage}</p></div>}
                    {layanan &&
                      layanan.map((layanan, index) => (
                        <div className="col-md-2 pt-1" key={layanan.id}>
                          <div className="card bordered hover-bg-black rounded p-1">
                            <div className="card">
                              <button
                                type="button"
                                className="rounded"
                                data-bs-toggle="modal"
                                data-bs-target={`#staticBackdrop${layanan.id}`}
                              >
                                <a href={layanan.link} target="blank">
                                  <img
                                    src={
                                      "https://mipp.demakkab.go.id/storage/" +
                                      layanan.icon
                                    }
                                    width="100%"
                                    height="120px;"
                                  />
                                </a>
                                <p>
                                  <b>{layanan.nama_web.substring(0, 17)}</b>
                                </p>
                              </button>
                            </div>
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
                <div className="carousel-item">
                  <div className="row">
                  {errorMessage && <div className="text-center p-4 pt-4"> <p>{errorMessage}</p></div>}
                    {layanan2 &&
                      layanan2.map((layanan2, index) => (
                        <div className="col-md-2 pt-1" key={layanan2.id}>
                          <div className="card bordered hover-bg-black rounded p-1">
                            <div className="card">
                              <button
                                type="button"
                                className="rounded"
                                data-bs-toggle="modal"
                                data-bs-target={`#staticBackdrop${layanan2.id}`}
                              >
                                <a href={layanan2.link} target="blank">
                                  <img
                                    src={
                                      "https://mipp.demakkab.go.id/storage/" +
                                      layanan2.icon
                                    }
                                    width="100%"
                                    height="120px;"
                                  />
                                </a>
                                <p>
                                  <b>{layanan2.nama_web.substring(0, 17)}</b>
                                </p>
                              </button>
                            </div>
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
                <div className="carousel-item">
                  <div className="row">
                  {errorMessage && <div className="text-center p-4 pt-4"> <p>{errorMessage}</p></div>}
                    {layanan3 &&
                      layanan3.map((layanan3, index) => (
                        <div className="col-md-2 pt-1" key={layanan3.id}>
                          <div className="card bordered hover-bg-black rounded p-1">
                            <div className="card">
                              <button
                                type="button"
                                className="rounded"
                                data-bs-toggle="modal"
                                data-bs-target={`#staticBackdrop${layanan3.id}`}
                              >
                                <a href={layanan3.link} target="blank">
                                  <img
                                    src={
                                      "https://mipp.demakkab.go.id/storage/" +
                                      layanan3.icon
                                    }
                                    width="100%"
                                    height="120px;"
                                  />
                                </a>
                                <p>
                                  <b>{layanan3.nama_web.substring(0, 15)}</b>
                                </p>
                              </button>
                            </div>
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
              </div>
              <button
                className="carousel-control-prev btn-w-2"
                type="button"
                data-bs-target="#carouselExampleControlsNoTouching"
                data-bs-slide="prev"
              >
                <span
                  className="carousel-control-prev-icon bg-btn-slide"
                  aria-hidden="true"
                ></span>
                <span className="visually-hidden">Previous</span>
              </button>
              <button
                className="carousel-control-next btn-w-2"
                type="button"
                data-bs-target="#carouselExampleControlsNoTouching"
                data-bs-slide="next"
              >
                <span
                  className="carousel-control-next-icon bg-btn-slide"
                  aria-hidden="true"
                ></span>
                <span className="visually-hidden">Next</span>
              </button>
            </div>
          </div>

          <div className="section-title pt-4 ">
            <Link to="/layanan_mipp">
              <h2 className="text-selengkapnya">
                <b>
                  <i className="bi bi-arrow-right-circle"></i>
                  jelajahi layanan
                </b>
              </h2>
            </Link>
          </div>
        </div>
      </section>
    </div>
  );
};

export default DataMipp;
