import axios from "axios";
import React, { useState } from "react";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import SmallError from "../../component/error/SmallError";


const Login = (props) => {


  const [email, setEmail] = useState();
  const [password, setPassword] = useState();

  const [error, setError] = useState();

  const navigate = useNavigate();

  function login() {
    axios
      .post("http://localhost:8000/api/login",{
            email: email,
            password: password,
      })
      .then(function (response) {
        console.log(response);
        localStorage.setItem("token", response.data.token);
        localStorage.setItem("role", response.data.data.role_id);
        props.setToken(response.data.token);
        props.setRole(response.data.data.role_id);
        if (response.data.data.role_id === 1) {
          navigate("/admin/dashboard");
        } else {
          navigate("/");
        }
      })
      .catch(function (error) {
        console.log(error);
        // setError(error.response.data.errors);
        // if(error.response && error.response.data.errors === 401){
        //   setError('Email atau Password Salah')
         
        // }
        if (error.response && error.response.status === 401) {
          // setError('Email atau password salah !'); // Pesan kesalahan untuk email/password salah
          setError(error.response.data);
          console.log(error.response.data);
        }else{    
          console.log(error.response.data.errors);
          setError(error.response.data.errors);
          // setError('Terjadi kesalahan saat mencoba login.'); //
        }
        

      });
  }

  return (
    <>
    <div style={{backgroundColor:"#d1d1d1",display:"flex",height:"100vh"}}>
      <div className="container mt-5 justify-content-center col-md-3 display-flex">
        <Row>
          <Col>
            <Card className="shadow border-radius">
              <Card.Header className="text-center bg-info text-dark header-radius">
                <b>Adiministrator</b>
              </Card.Header>
              <Card.Body>
              {/* {error && <SmallError error={error}></SmallError>} */}
              {error && <SmallError error={error.message}></SmallError>}
              <Form>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Alamat Email</Form.Label>
                  <Form.Control
                    onChange={(e) => setEmail(e.target.value)}
                    type="email"
                    placeholder="Email..." required
                  />
                  {error && <SmallError error={error.email && error.email[0]}></SmallError>}
                  {/* {error && (
                    <SmallError
                      error={error.email && error.email[0]}
                    ></SmallError>
                  )} */}
                </Form.Group>

                <Form.Group className="mb-3" controlId="formBasicPassword">
                  <Form.Label>Password</Form.Label>
                  <Form.Control
                    onChange={(e) => setPassword(e.target.value)}
                    type="password"
                    placeholder="********"
                  />
                  {error && (
                    <small className="text-danger">
                      {error.password && error.password[0]}
                    </small>
                  )}
                </Form.Group>

                <Button
                  variant="outline-primary btn-sm"
                  style={{ float: "right" }}
                  onClick={login}
                >
                  <b>LogIn</b>
                </Button>
              </Form>
            </Card.Body>
              <div className="container">
                <Link exact="true" to={"/"}>
                  <b style={{ color: "#6c757d" }}>Back</b>
                </Link>
              </div>
            </Card>
          </Col>
        </Row>
      </div>
    </div>
    </>
  );
};

export default Login;
