import React from 'react'
import { Link } from 'react-router-dom'

const Breadcrumbs = (props) => {
    
  return (
    <div>
    <section className="breadcrumbs">
        <div className="container">

            <div className="d-flex justify-content-between align-items-center">

                <ol>
                    <li>
                        <h5><Link to={"/"}><i className="bi bi-arrow-left-circle"></i> Kembali</Link></h5>
                    </li>
                </ol>
            </div>

        </div>
    </section>
    </div>
  )
}

export default Breadcrumbs