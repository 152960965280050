import React from 'react'
import DataInfografis from './DataInfografis'

const VideoInfografis = (props) => {
  return (
    <div>
         <div className="section-title pt-4">
                <p className="p-dsc">Informasi Seputar Demak</p>
                <h1 className="h-dsc">Update Informasi Channel Siaran D'Tivi Dan Infografis SatuData Demak</h1>
         </div>
         <div className="row">
          <div className="col-md-6 card bordered hover-bg-black rounded p-1">

              <iframe width="100%" height="320px"
                  src="https://www.youtube.com/embed/videoseries?list=PL_88kDTQnCkyk6AWDtRCUBHzgl3IMb_as&autoplay=1&loop=1&mute=1"
                  frameBorder="1" allow="autoplay; encrypted-media" rounded="true"></iframe>

          </div>
          <div className="col-md-6">
          <DataInfografis/>

          </div>
         </div>
         <div className="section-title pt-4">
            <a href="https://satudata.demakkab.go.id/infografis" target="blank">
              <h2 className="text-selengkapnya">
                <b>
                  <i className="bi bi-arrow-right-circle"></i>
                  lihat semua infografis{" "}
                </b>
              </h2>
            </a>
        </div> 
    </div>
  )
}

export default VideoInfografis