import axios from 'axios';
import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom';

const Logout = (props) => {
    const navigate = useNavigate()

    useEffect(() => {
      logout();
    });
  
    function logout() {
      axios({
        method: "post",
        url: "http://localhost:8000/api/logout",
        headers: {"Authorization" : `Bearer ${props.token}`},
      }).then(function (response) {
  
        console.log(response);
        props.setToken();
        props.setRole();
        localStorage.removeItem('token');
        localStorage.removeItem('role');
        navigate('/');
  
      }).catch(function(error){
        console.log(error);
      })
    }
  
}

export default Logout