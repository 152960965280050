import React from 'react'
import Breadcrumbs from '../layouts/Breadcrumbs'


const Error404 = () => {
  return (
    <div>
       <Breadcrumbs />
       <div class="container text-center mb-4">
         <img src="/404.png" width="38%;" />
         <h6>Halaman Tidak Ditemukan !</h6>

       </div>
    </div>
  )
}

export default Error404