
import Navbar from './component/layouts/Navbar';
import {
  BrowserRouter as Router,
} from "react-router-dom";
import RouteComponent from './component/route/RouteComponent';
import Footer from './component/layouts/Footer';
import { useEffect, useState } from 'react';

function App() {
  const [token, setToken] = useState()
  const [role, setRole]  = useState()

  useEffect(() => {
    getRole()
  })
  useEffect(() => {
    getToken()
  })
  
  function getToken(){
    setToken(localStorage.getItem('token'))
  }
  function getRole(){
    setRole(localStorage.getItem('role'))
  }

   return (
      <Router>
      <>
        <Navbar />
        <RouteComponent token={token} setToken={setToken} role={role} setRole={setRole}/>
        <Footer/>   
      </>
    </Router>
    );
}

export default App;
