import React from 'react'
import Breadcrumbs from './layouts/Breadcrumbs'

const Faq = (props) => {
  return (
    <div>
      <title>Wali Warga Demak - FAQ</title>
      <Breadcrumbs />
      <section className="inner-page p-panduan">
        <div className="container card p-4 shadow">
          <div className="text-center">
            <h5>
              <b className="shadow rounded p-2 title-panduan">
                Frequently Asked Question ( FAQ )
              </b>
            </h5>
          </div>
          <div className="accordion accordion-flush p-2" id="accordionFlushExample">
                <div className="accordion-item">
                    <h2 className="accordion-header" id="flush-headingOne">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                            data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                            Apa Itu Aplikasi Wali Warga?
                        </button>
                    </h2>
                    <div id="flush-collapseOne" className="accordion-collapse collapse" aria-labelledby="flush-headingOne"
                        data-bs-parent="#accordionFlushExample">
                        <div className="accordion-body">
                            <p>Apa sih Apilkasi Wali Warga itu ? Aplikasi Wali Warga adalah Sebuah Portal Virtual Pemerintah
                                Kabupaten Demak dalam rangka memberikan layanan langsung kepada semua masyarakat di
                                Kabupaten Demak</p>
                        </div>
                    </div>
                </div>
                <div className="accordion-item">
                    <h2 className="accordion-header" id="flush-headingTwo">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                            data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
                            Bagaimana Memasang Aplikasi Wali Warga di SmartPhone?
                        </button>
                    </h2>
                    <div id="flush-collapseTwo" className="accordion-collapse collapse" aria-labelledby="flush-headingTwo"
                        data-bs-parent="#accordionFlushExample">
                        <div className="accordion-body">
                            <p> Untuk SmartPhone Dengan OS Android, Silahkan Masuk Ke Playstore Kemudian Search Pencarian
                                Dengan Kata Kunci Wali Warga atau Search Dengan Kata Kunci Dinkominfo Demak, Kemudian Pilih
                                Aplikasi Wali Warga, Selanjutnya Pilih Dan Install</p>
                            <p>
                                Untuk SmartPhone Dengan OS IOS Sementara Aplikasi Wali Warga Belum Terdesia.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="accordion-item">
                    <h2 className="accordion-header" id="flush-headingThree">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                            data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">
                            Bagaimana Cara Menggunakan Aplikasi Wali Warga ?
                        </button>
                    </h2>
                    <div id="flush-collapseThree" className="accordion-collapse collapse" aria-labelledby="flush-headingThree"
                        data-bs-parent="#accordionFlushExample">
                        <div className="accordion-body">
                            <p>Untuk Panduan Cara Penggunaan Aplikasi Wali Warga, Silahkan Download Panduan <a
                                    href="3">Disini !</a></p>
                        </div>
                    </div>

                </div>
            </div>
        </div>
      </section>
    </div>
  )
}

export default Faq