import React, { useEffect } from "react";
import DataBerita from "./berita/DataBerita";
import DataDsc from "./layanandsc/DataDsc";
import DataMipp from "./layananmipp/DataMipp";
const Dashboard = () => {
  useEffect(() => {
    const shouldReload1 = !window.sessionStorage.getItem('loaded1');
    const shouldReload2 = !window.sessionStorage.getItem('loaded2');
    if (shouldReload1 && shouldReload2) {
      // Reload the page once
      window.location.reload();
      window.sessionStorage.setItem('loaded1', 'true');
      window.location.reload();
      window.sessionStorage.setItem('loaded2', 'true');
    }
  }, []);
  return (
    <div>
        <title>Wali Warga Demak</title>
      <div>
        <section
          id="hero"
          className="d-flex flex-column justify-content-end align-items-center"
        >
          <div
            id="heroCarousel"
            data-bs-interval="4000"
            className="container carousel carousel-fade"
            data-bs-ride="carousel"
          >
            <div className="carousel-container">
              <div className="row">
                <div className="carousel-item active">
                  <div className="carousel-container">
                    <div className="row">
                      <div className="col-md-7">
                        <h2 className="animate__animated animate__fadeInDown">
                          Wali Warga Demak
                        </h2>
                        <p className="animate__animated fanimate__adeInUp">
                          Aplikasi Wali Warga Merupakan Portal Maya
                          Pemerintah Kabupaten Demak Dalam Rangka Memberikan
                          Layanan Langsung Kepada Semua Masyarakat Kabupaten
                          Demak
                        </p>
                        <a
                          href="https://play.google.com/store/apps/details?id=com.rb.waliwarga.demak"
                          className="btn-get-started animate__animated animate__fadeInUp scrollto"
                        >
                          <i className="bi bi-google-play"></i> Google Play
                        </a>
                        <a
                          href="#"
                          className="btn-get-started animate__animated animate__fadeInUp scrollto"
                        >
                          <i className="bi bi-apple"></i> App Store
                        </a>
                      </div>
                      <div className="col-md-5">
                        <img
                          src="/Selecao/assets/img/banner1.png"
                          alt=""
                          width="100%"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="carousel-item">
                  <div className="carousel-container">
                    <div className="row">
                      <div className="col-md-7">
                        <h2 className="animate__animated animate__fadeInDown font-custom">
                          Lindungi Diri dan Keluarga dengan Vaksin Booster
                        </h2>
                        <p className="animate__animated fanimate__adeInUp">
                          Lindungi Diri Dan Keluarga Kita Dari Virus Covid-19
                          Dengan Melakukan Vaksinasi Boster 3 di Puskesmas Terdekat
                        </p>
                      </div>
                      <div className="col-md-5">
                        <img
                          src="/Selecao/assets/img/banner2.png"
                          alt=""
                          width="100%"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <svg
            className="hero-waves"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            viewBox="0 24 150 28 "
            preserveAspectRatio="none"
          >
            <defs>
              <path
                id="wave-path"
                d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z"
              />
            </defs>
            <g className="wave1">
              <use
                xlinkHref="#wave-path"
                x="50"
                y="3"
                fill="rgba(255,255,255, .1)"
              />
            </g>
            <g className="wave2">
              <use
                xlinkHref="#wave-path"
                x="50"
                y="0"
                fill="rgba(255,255,255, .2)"
              />
            </g>
            <g className="wave3">
              <use xlinkHref="#wave-path" x="50" y="9" fill="#fff" />
            </g>
          </svg>
        </section>
      </div>
      <DataMipp/>
      <DataDsc/>
      <DataBerita/>
      
    </div>
  );
};

export default Dashboard;
